<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-card>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider
                      v-slot="{ errors }"
                      ref="name"
                      name="name"
                      rules="required|min:3|max:255"
                    >
                      <v-text-field
                        outlined
                        v-model="name"
                        :error-messages="errors"
                        counter
                        maxlength="255"
                        hint="The name of the sponsor"
                        label="Name"
                        required
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="identification"
                      rules="required|min:6|max:255"
                    >
                      <v-text-field
                        outlined
                        v-model="identification"
                        :error-messages="errors"
                        counter
                        maxlength="255"
                        hint="The id of the sponsor"
                        label="Sponsor ID"
                        required
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required|min:6|max:255"
                    >
                      <v-text-field
                        outlined
                        v-model="description"
                        :error-messages="errors"
                        counter
                        maxlength="255"
                        hint="The sponsor description."
                        label="Description"
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="is_active"
                      rules="required"
                    >
                      <v-radio-group
                        v-model="is_active"
                        row
                        aria-required="true"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          <div>Status</div>
                        </template>
                        <fieldset>
                          <legend class="d-none" aria-hidden="true">
                            Status
                          </legend>
                          <v-radio :label="`Active`" :value="true"></v-radio>
                          <v-radio
                            :label="`Inactive`"
                            :value="false"
                            :disabled="checkIfStatusShouldBeDisabled"
                          ></v-radio>
                        </fieldset>
                      </v-radio-group>
                      <div v-if="checkIfStatusShouldBeDisabled">
                        All sponsor enagements need to be inactive before
                        changing the sponsor status to inactive
                      </div>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="business_practice"
                      rules="required"
                    >
                      <v-select
                        outlined
                        :items="business_practices"
                        label="Business Practice"
                        :error-messages="errors"
                        v-model="business_practice"
                        aria-required="true"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="country"
                      rules="required"
                    >
                      <v-select
                        outlined
                        :items="countries"
                        label="Country"
                        :error-messages="errors"
                        v-model="country"
                        aria-required="true"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required, min, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("passive");

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("min", {
  ...min,
  message: "Minimum {length} characters.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

export default {
  name: "SponsorsForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      business_practices: [
        "BioPharma",
        "BioTech",
        "HealthTec",
        "Consumer Sciences",
      ],
      isLoading: true,
      isSubmitting: false,
      originalIdentification: "",
      snackbar: false,
    };
  },
  computed: {
    ...mapFields("sponsors", {
      business_practice: "sponsor.business_practice",
      country: "sponsor.country",
      description: "sponsor.description",
      identification: "sponsor.identification",
      is_active: "sponsor.is_active",
      name: "sponsor.name",
    }),
    ...mapState("countries", ["countries", "error"]),
    ...mapState("sponsors", ["error"]),
    ...mapGetters("sponsors", ["sponsorEngagements"]),
    checkIfStatusShouldBeDisabled() {
      let disable = false;
      if (this.is_active) {
        //if active and there are active engagements, then disabled input until all sponsor enagements are not active anymore
        if (this.sponsorEngagements.length > 0) {
          for (let i = 0; i < this.sponsorEngagements.length; i++) {
            if (this.sponsorEngagements[i].attributes.isActive) {
              disable = true;
              break;
            }
          }
        }
      }
      return disable;
    },
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("sponsors", [
      "addSponsor",
      "clearSponsor",
      "clearSponsors",
      "editSponsor",
      "getSponsor",
      "identificationIsUnique",
    ]),
    ...mapActions("countries", ["getCountries"]),

    async submit() {
      const formValid = await this.$refs.observer.validate();

      if (this.isSubmitting || !formValid) return;
      let success = false;
      this.isSubmitting = true;
      if (this.isNew || this.originalIdentification !== this.identification) {
        success = await this.identificationIsUnique(this.identification);
        if (!success) {
          this.$refs.observer.setErrors({
            identification: ["This identification has already been used"],
          });
          this.isSubmitting = false;
          return;
        }
      }
      if (this.isNew) {
        success = await this.addSponsor();
      } else {
        success = await this.editSponsor(this.id);
      }
      if (success !== false) {
        await this.$router.push({
          name: "SponsorsView",
          params: { id: success },
        });
      } else {
        if (this.error === "Sponsor name must be unique.") {
          this.$refs.name.applyResult({
            errors: ["This name has already been used"], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }
        this.snackbar = !!this.error;
      }
      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.getCountries();
    this.isLoading = true;
    let actionButtons = [];
    if (this.isNew) {
      await this.clearSponsor();
      this.setTitleObject("Sponsor");
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/sponsors",
        },
        index: 0,
      });
    } else {
      await this.getSponsor(this.id).catch(() => {
        this.$router.push({ name: "NotFound" });
      });
      this.originalIdentification = this.identification;
      this.setTitleObject(this.name);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/sponsors/view/" + this.id,
        },
        index: 0,
      });
    }
    this.isLoading = false;
    this.setBreadcrumbs();
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearSponsor();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
